<div class="container">
  <div class="pic"></div>
  <div class="title">{{ 'verif_tip00' | translate }}</div>
  <p>{{ 'enter_phone00' | translate }}{{ 'enter_phone01' | translate }}</p>
  <!---->
  <div class="wrap-phone" [ngClass]="{ active: showError }">
    <span class="text-wrap"> {{ currentPhoneZone }} {{ this.phoneNum.slice(0, -6) }} </span>
    <code-input
      [inputType]="'number'"
      class="code-input"
      [isCodeHidden]="false"
      [isNonDigitsCode]="false"
      [codeLength]="6"
      (codeChanged)="onCodeChanged($event)"
      [class.error]="!isCorrect && inputPhoneNum.length === 6"
      (codeCompleted)="onCodeCompleted($event)"
    ></code-input>
  </div>

  <div class="error-msg" *ngIf="!isCorrect && inputPhoneNum.length === 6">
    {{ 'enter_err00' | translate }}
  </div>
  <div class="btn-container">
    <button class="submit-btn btn-sm onact96-not" [disabled]="!isCorrect" (click)="dismiss()">
      {{ 'sure' | translate }}
    </button>
  </div>
</div>
